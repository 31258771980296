import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Layout } from 'antd';
import './index.css'

import Menu from '../common/Menu';
import MenuItem from '../common/MenuItem';
import DrawerComponent from '../common/Drawer';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';
import { MailOutlined, MenuOutlined } from '@ant-design/icons';
import { CATGORY_LINKS, LOGO_URL } from '../../utils/constant';

const { Header } = Layout;

const styles = {
  headerHamburger: () => ({
    color: '#fff',
  }),
  headerDrawer: {
    width: 'auto',
    borderRight: 'none',
  },
};

export default function HeaderComponent() {
  const [headerDrawerOpen, setHeaderDrawerOpen] = useState<boolean>(false);
  const matches = useMediaQuery('(min-width: 768px)');
  const [urlIndex, setUrlIndex] = useState<string[]>([]);


  function getURLIndex(url: string){
    CATGORY_LINKS.map((item: any, index: number)=>{
      if(url.includes(item.category)){
        setUrlIndex([`${index}`])
      }else {
        setUrlIndex([`${-1}`])
      }
    });
  }

  useEffect(() => {
    getURLIndex(location.pathname);
  }, [location]);

  const MenuItems = (
    <>
      {CATGORY_LINKS?.map((cat: any, index: any) => (
      <MenuItem key={index}>
        <Link className='menu-items' to={cat?.link} >{cat.label}</Link>
      </MenuItem>
      ))}
      <MenuItem key={'mlb'}>
        <a href="https://www.sportsfeed24.to/streams/mlb" rel="noreferrer" target='_blank' className='menu-items'>MLB</a>
      </MenuItem>
      <MenuItem key={'nhl'}>
      <a href="https://www.sportsfeed24.to/streams/nhl" rel="noreferrer" target='_blank' className='menu-items'>NHL</a>
      </MenuItem>
    </>
  );

  return (
    <Header
    style={{display: 'flex', justifyContent: matches ? 'space-between': 'space-evenly'}}
    >
      <div
        className='header-body'
      >
        <Link to='/'>
          <div style={matches ? { display: 'flex', justifyContent: 'center', alignItems:'center' }: { display: 'flex', justifyContent:'space-between', alignItems:'center' }}>
            <img className='logo-img' src={LOGO_URL} alt='website-logo' />
            <h1 className={matches ? 'site-title': 'site-title-mobile'}>Sports<span style={{color: '#34cfab'}}>Feed</span>24</h1>
          </div>
        </Link>
        <div aria-hidden="true" className='traingle'></div>
        {matches ? (
          <Menu mode='horizontal' defaultSelectedKeys={urlIndex} style={{ width: '100%', background: '#123e39' }}>
            {MenuItems}
          </Menu>
        ) : (
          <div onClick={() => setHeaderDrawerOpen(true)}>
            <MenuOutlined style={styles.headerHamburger()} />
          </div>
        )}
      </div>
      <div style={{display: 'flex', justifyContent: 'right'}}>
        <Link to='/contact-us'>
          {matches ? 
          <Button shape="round" style={{background: '#000000', color: '#ffffff'}}>
          Contact Us
          </Button>:
          <MailOutlined style={{ color: '#f0f0f0', marginLeft: '10px' }}></MailOutlined>}
        </Link>
      </div>
      <DrawerComponent
        title='Sportsfeed24'
        open={headerDrawerOpen}
        onClose={() => setHeaderDrawerOpen(false)}
      >
        {
          <Menu
            onClick={() => setHeaderDrawerOpen(false)}
            mode='vertical'
            defaultSelectedKeys={urlIndex}
            style={styles.headerDrawer}
          >
            {MenuItems}
          </Menu>
        }
      </DrawerComponent>
    </Header>
  );
}
