import { Routes, Route, Navigate } from 'react-router-dom';

import HeaderComponent from './components/Header';
import Home from './views/Home';
import Fixture from './views/Fixture';
import Layout, { Content, Footer, Header } from 'antd/es/layout/layout';
import FooterComponent from './components/Footer';
import ContactUsComponent from './components/ContactUs';
import PrivacyPolicy from './components/PrivacyPolicy';
import AboutUs from './components/AboutUs';
import Standings from './views/Standings';
import FixtureList from './views/FixtureList';
import AddStreamComponent from './components/addStream';
import UpdateStreamComponent from './components/UpdateStream';
import { styles } from './styles';

const footerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: '#fff',
  backgroundColor: '#000000',
}

const Router = () => {
  return (
    <Layout>
      <Header style={styles.header}>
        <HeaderComponent></HeaderComponent>
      </Header>
      <Content style={{background: '#222121',textAlign: 'center'}}>
        <Routes>
          <Route
            path='/fixtures/:categoryName/:subCategoryName'
            element={
              <Home />
            }
          />
          <Route
            path='/'
            element={
              <Home />
            }
          />
          <Route
            path='/fixture/:teams'
            element={
              <Fixture />
            }
          />
          <Route
            path='/fixture/:teams/add'
            element={
              <AddStreamComponent />
            }
          />
          <Route
            path='/fixture/:teams/update'
            element={
              <UpdateStreamComponent />
            }
          />
          <Route
            path='/leagues/standings'
            element={
              <Standings />
            }
          />
          <Route
            path='/fixtures/league/:leagueName'
            element={
              <FixtureList />
            }
          />
          <Route
            path='/fixtures/team/:teamName'
            element={
              <FixtureList />
            }
          />
          <Route
            path='/about'
            element={
              <AboutUs />
            }
          />
          <Route
            path='/contact-us'
            element={
              <ContactUsComponent />
            }
          />
          <Route
            path='/privacy-policy'
            element={
              <PrivacyPolicy />
            }
          />
          <Route path='*' element={<Navigate to="/" />} />
        </Routes>
      </Content>
      <Footer style={footerStyle}>
        <FooterComponent></FooterComponent>
      </Footer>
    </Layout>
  );
};

export default Router;
