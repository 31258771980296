import { Col, Image, Row, Typography } from 'antd';
// import logo from '../../assets/logo/logo.webp';
import dmcaImage from '../../assets/dmcaproteted.png';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { styles } from '../../styles';
import { LOGO_URL } from '../../utils/constant';

export default function FooterComponent() {

  return (
    <>
    <Row gutter={24} style={{...styles.flexDisplay, marginBottom: '10px'}}>
        <Col span={16}>
            <Row gutter={24} style={styles.flexDisplay}>
                <Image width={80} src={dmcaImage} />
            </Row>
            <Row gutter={24}>
                <Typography.Text style={{color: '#fff'}}>
                Disclaimer: This website does not host any of the videos referenced herein. Links to streams from external platforms such as YouTube are provided solely for user convenience. The website disclaims any responsibility for the legality of the content. All legal inquiries or issues should be addressed directly to the respective media file owners or hosting platforms.
                </Typography.Text>
            </Row>
        </Col>
    </Row>
    <Row gutter={24} style={styles.flexDisplay}>
        <Col span={4}>
            <Image width={40} src={LOGO_URL} />
        </Col>
        <Col span={4}>
            <Link to={'/about'} >
                <Typography.Text style={{color: '#fff'}}>About Us</Typography.Text>
            </Link>
        </Col>
        <Col span={4}>
            <Link to={'/privacy-policy'} >
                <Typography.Text style={{color: '#fff'}}>Privacy Policy</Typography.Text>
            </Link>
        </Col>
        <Col span={4}>
            <Link to={'/contact-us'} >
                <Typography.Text style={{color: '#fff'}}>Contact Us</Typography.Text>
            </Link>
        </Col>
        <Col span={8}>
            <Typography.Text style={{color: '#fff'}}>Copyright © {dayjs(new Date()).format('YYYY')} Sportsfeed24 All rights reserved.</Typography.Text>
        </Col>
    </Row>
    </>
  );
}
